<template>
  <Layout>
    <div class="card">
      <FormulateForm :values="search" @submit="searchForDriver" class="m-5">
        <div class="d-flex justify-content-center">
          <FormulateInput
            type="search"
            name="driver"
            label="Search by Driver ID or Mobile Number"
            placeholder="Search Like: B-1234, 5xxxxxx"
          />

          <FormulateInput
            type="submit"
            style="padding-top: 36px; margin-left: 12px"
            :disabled="searching"
            :label="searching ? 'Searching' : 'Search'"
          />
        </div>
      </FormulateForm>
      <div v-if="driver" class="driver-detail">
        <div>
          <img
            class="driver-profile-pic"
            :src="driver.profile_pic"
            alt=""
            srcset=""
          />
          Driver ID : <strong>{{ driver.driver_id }}</strong> <br />
          Email : {{ driver.email }} <br />
          Phone : +{{ driver.country_code }}-{{ driver.phone_number }}
        </div>

        <div class="mb-5">
          Vehicle Number {{ driver.vehicles[0].plate_category }}
          {{ driver.vehicles[0].plate_number }}<br />
          Model:{{ driver.vehicles[0].model.name }} ({{
            driver.vehicles[0].model.manufacture.name
          }}
          )
        </div>
        <FormulateInput
          type="button"
          style="margin-bottom: 120px"
          label="Inspect"
          :disabled="!driver.vehicles[0].current_ad"
          @click="
            $router.push({
              name: 'InspectVehicleAction',
              params: { id: driver.vehicles[0].current_ad._id },
            })
          "
        />
      </div>
      <div v-else style="padding-bottom: 148px">
        <h6 v-if="!searchComplete" class="text-center my-5">Please Enter Driver Id in search box</h6>
        <h6 v-else class="text-center my-5">Could not find driver</h6>
      </div>
    </div>
  </Layout>
</template>

<script>
import { job } from "@/config/api/partner/job";
import Layout from "@/views/layouts/main";
import { partnerMethods } from "@/state/helpers.js";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      search: {},
      driver: undefined,
      searching: false,
      searchComplete: false,
    };
  },
  methods: {
    ...partnerMethods,
    searchForDriver(value) {
      const api = job.searchForDriver;
      api.params = {
        search: value.driver.trim(),
      };
      this.searching = true;
      this.generateAPI(api)
        .then((res) => {
          this.driver = res.data.user;
          this.selectDriver(this.driver);
        })
        .finally(() => {
          this.searching = false;
          this.searchComplete = true;

        });
    },
  },
};
</script>

<style scoped>
.driver-profile-pic {
  display: block;
  margin: auto;
  width: 120px;
  margin-bottom: 28px;
}
.driver-detail {
  text-align: center;
}
</style>
